import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },

  // MARK::Auth route
  {
    path: 'auth',
    canActivate: [LoginGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },

  // MARK::home page
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },

  // MARK::event landing page
  {
    path: 'event',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/event-page/event-page.module').then(
        (m) => m.EventPageModule,
      ),
  },

  // MARK::Profile page
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfileModule),
  },

  // MARK::Presensi page
  {
    path: 'presensi',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/presensi/presensi.module').then((m) => m.PresensiModule),
  },

  // MARK::Leaderboard page
  {
    path: 'leaderboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/leaderboard/leaderboard.module').then(
        (m) => m.LeaderboardModule,
      ),
  },

  // MARK::booth page
  {
    path: 'booth',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/booth/booth.module').then((m) => m.BoothModule),
  },

  // MARK::quiz page
  {
    path: 'form',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/form/form.module').then((m) => m.FormModule),
  },

  {
    path: 'survei',
    redirectTo: 'form',
  },

  {
    path: 'schedule',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/schedule/schedule.module').then((m) => m.ScheduleModule),
  },

  {
    path: 'agenda',
    redirectTo: 'schedule',
  },

  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
