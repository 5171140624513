import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-popup-alert',
  templateUrl: './popup-alert.component.html',
  styleUrls: ['./popup-alert.component.scss'],
  standalone: false,
})
export class PopupAlertComponent implements OnInit {
  title: string = '';
  text: string = '';
  subtext: string = '';
  type: string = '';
  optBtn: any = {
    confirmLabel: 'Ok',
    cancelLabel: 'No',
  }

  constructor(
    private ref: DynamicDialogRef,
    private dialog:DynamicDialogConfig
  ) { }

  ngOnInit() {
    if (this.dialog.data) {
      this.title = this.dialog.data.title ?? '';
      this.text = this.dialog.data.text ?? '';
      this.subtext = this.dialog.data.subtext ?? '';
      this.type = this.dialog.data.type ?? '';
      this.optBtn = this.dialog.data.optBtn ?? {confirmLabel: 'Ok', cancelLabel: 'No'};
    }
  }

  close(){
    this.ref.close();
  }

  submit(){
    this.ref.close({data:'Ok'});
  }

}
