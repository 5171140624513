<i class="pi pi-times absolute right-3 top-3 cursor-pointer text-2xl font-extrabold text-white" (click)="close()"></i>

@if (data.length === 1) {
<!-- MARK::type image -->
<div class="mx-auto max-w-80">
  <!-- <img src="assets/images/banner.jpg" alt="" class="w-full rounded-lg cursor-pointer" /> -->
  <img [src]="data[0].media" alt="" class="cursor-pointer rounded-lg w-full" (click)="redirect(data[0])" />
  @if (isValidUrl(data[0].url)) {
    <div class="text-center text-xs italic text-white">
      Klik banner untuk informasi selengkapnya
    </div>
  }
</div>

} @else {
<!-- MARK::type carousel -->
  <p-carousel [value]="data" [numVisible]="1" [numScroll]="1" [showNavigators]="false" [showIndicators]="true" [circular]="true"
    styleClass="custom-carousel" autoplayInterval="5000">
    <ng-template pTemplate="item" let-splash>
      <img
        [src]="splash.media"
        class="h-full min-h-80 max-h-[500px] object-contain cursor-pointer rounded-lg m-auto"
        (click)="redirect(splash)"
        alt=""
      />
      @if (isValidUrl(splash.url)) {
        <div class="text-center text-xs italic text-white mt-4">
          Klik banner untuk informasi selengkapnya
        </div>
      }
    </ng-template>
  </p-carousel>
}
