import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-popup-media',
  standalone: false,

  templateUrl: './popup-media.component.html',
  styleUrl: './popup-media.component.scss',
})
export class PopupMediaComponent {
  data: any[] = [];
  optBtn: any = {
    confirmLabel: 'Ok',
    cancelLabel: 'No',
  }

  constructor(
    private ref: DynamicDialogRef,
    private dialog: DynamicDialogConfig,
  ) {}

  ngOnInit() {
    if (this.dialog.data) {
      this.data = this.dialog.data.data ?? [];
      this.optBtn = this.dialog.data.optBtn ?? {confirmLabel: 'Ok', cancelLabel: 'No'};
    }
  }

  close() {
    this.ref.close();
  }

  redirect(data: any) {
    let newUrl: string;
    if (this.isValidUrl(data.url)) {
      if (!/^https?:\/\//.test(data.url)) {
        newUrl = 'https://' + data.url;
      } else {
        newUrl = data.url;
      }
      window.open(newUrl, '_blank');
    }
    return;
  }

  isValidUrl(url: string) {
    return /^(https?:\/\/)?(www\.)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(url)
  }
}
