<div class="mb-5 text-center">
  @if (type === "success") {
    <img src="assets/media/icon-check.png" alt="" class="mb-5 w-20" />
  } @else if (type !== "success") {
    <img src="assets/media/icon-warning.png" alt="" class="mb-5 w-20" />
  }
  <h5 class="mb-5 text-center font-semibold">{{ title }}</h5>
  <p class="break-words text-center font-semibold" [innerHTML]="text"></p>
  <p
    class="text-center text-xs font-semibold text-[#3D489C]"
    [innerHTML]="subtext"
  ></p>
</div>
@if (type === "success" || type === "info") {
  <button
    pButton
    pRipple
    [label]="optBtn?.confirmLabel ?? 'Ok'"
    (click)="close()"
    class="primary w-full"
  ></button>
} @else if (type !== "success" && type !== "info") {
  <div class="flex items-center gap-3">
    <button
      pButton
      pRipple
      [label]="optBtn?.cancelLabel ?? 'No'"
      (click)="close()"
      class="primary-outline w-full"
    ></button>
    <button
      pButton
      pRipple
      [label]="optBtn?.confirmLabel ?? 'Yes'"
      (click)="submit()"
      class="primary w-full"
    ></button>
  </div>
}
