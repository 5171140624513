import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormGroup } from '@angular/forms';

import { StorageMap } from '@ngx-pwa/local-storage';
import { firstValueFrom, lastValueFrom, Subject } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import Swal from 'sweetalert2';

import { Users } from '../core/models/auth.model';

// MARK: Components
import { PopupAlertComponent } from '../popup/popup-alert/popup-alert.component';

import * as CryptoJS from 'crypto-js';
import { PopupMediaComponent } from '../popup/popup-media/popup-media.component';

export interface Paginator_m {
  page: number;
  limit: number;
  lastPage: number;
  totalPage: number;
}

export const Paginator = {
  page: 1,
  limit: 10,
  lastPage: 0,
  totalPage: 0,
};

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  users!: Users;

  private password = '1324576890abcdef1324576890abcdef';
  private iv = 'abcdef1324576890abcdef1324576890';

  routerData: Subject<any> = new Subject<any>();

  constructor(
    private strorage: StorageMap,
    public dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  setStorage(name: string, value: any) {
    return lastValueFrom(this.strorage.set(name, value));
  }

  getStorage(name: string) {
    return lastValueFrom(this.strorage.get(name));
  }

  //-----------------MARK: ALERT---->
  async showAlert(
    title?: any,
    text?: any,
    type?: any,
    option?: any,
    subtext?: any,
  ) {
    const popupData = {
      title: title,
      text: text,
      type: type,
      optBtn: option,
      subtext: subtext,
    };

    const alert = this.dialogService.open(PopupAlertComponent, {
      width: '320px',
      data: popupData,
      baseZIndex: 10000,
      showHeader: false, // Menghilangkan header dialog
    });

    let result;
    await firstValueFrom(alert?.onClose).then((res) => {
      return (result = res);
    });
    return result;
  }

  async showMedia(media: any[], option?: any) {
    const popupData = {
      data: media,
      optBtn: option,
    };

    const alert = this.dialogService.open(PopupMediaComponent, {
      width: '100%',
      data: popupData,
      baseZIndex: 10000,
      showHeader: false, // Menghilangkan header dialog
      styleClass: 'transparent-dialog', // Tambahkan kelas kustom
    });

    let result;
    await firstValueFrom(alert.onClose).then((res) => {
      return (result = res);
    });
    return result;
  }

  // MARKL: hashing encrypt and decrypt
  /**
   * Encrypt the given plaintext
   * @param plaintext - String to be encrypted
   * @returns Encrypted string in Base64 format
   */
  hashData(plaintext: string): string {
    const key = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.password).toString(),
    );
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.iv).toString().substr(0, 32),
    );
    const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv });
    return encrypted.toString();
  }

  /**
   * Decrypt the given encrypted string
   * @param encryptedData - Encrypted string in Base64 format
   * @returns Decrypted string (plaintext)
   */
  unHashData(encryptedData: string): string {
    const key = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.password).toString(),
    );
    const iv = CryptoJS.enc.Hex.parse(
      CryptoJS.SHA256(this.iv).toString().substr(0, 32),
    );
    const decrypted = CryptoJS.AES.decrypt(encryptedData, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  isSameDate(date1: any, date2: any) {
    return new Date(date1).getDate() === new Date(date2).getDate();
  }

  async showSuccessAlert(title: string, text: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      showCloseButton: true,
      color: 'white',
      background: '#25933D',
      width: 512,
      customClass: {
        title: '!text-[14px] !text-white !m-0 !mt-2',
        icon: 'border-none',
      },
      timer: 3000,
    });
    return Toast.fire({
      icon: 'success',
      iconHtml:
        '<span class="pi pi-check content-center shadow-none font-black text-[14px] text-[#25933D] text-center w-[24px] h-[24px] bg-white rounded-full"></span>',
      title: text,
    }).then((result) => result.isConfirmed);
  }

  async showErrorAlert(title: string, text: string): Promise<boolean> {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom',
      showConfirmButton: false,
      showCloseButton: true,
      color: 'white',
      background: '#C42F35',
      width: 512,
      customClass: {
        title: '!text-[14px] !text-white !m-0 !mt-2',
        icon: 'border-none',
      },
      timer: 3000,
    });
    return Toast.fire({
      icon: 'error',
      iconHtml:
        '<span class="pi pi-times content-center shadow-none font-black text-[14px] text-[#C42F35] text-center w-[24px] h-[24px] bg-white rounded-full"></span>',
      title: text,
    }).then((result) => result.isConfirmed);
  }

  getInitial(name: any) {
    if (name) {
      let member_name = name.split(' ');
      if (member_name.length > 1) {
        return (
          member_name[0].charAt(0).toUpperCase() +
          member_name[1].charAt(0).toUpperCase()
        );
      } else {
        return member_name[0].charAt(0).toUpperCase();
      }
    } else {
      return '';
    }
  }

  getTypeMedia(url: string) {
    if (url) {
      const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp', 'ico', 'tiff', 'tif'];
      const videoTypes = ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'flv', 'webm', 'vob', 'ogv', 'ogg', 'drc', 'mng', 'mts', 'm2ts', 'ts', 'm4v', '3gp', '3g2'];
      const docTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv', 'rtf', 'odt', 'ods', 'odp', 'odg', 'odf', 'odc', 'odb', 'odm', 'odp', 'odt', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots', 'otp', 'otg', 'otf', 'oti', 'oth', 'odft', 'odt', 'odm', 'ott', 'ots'];
      const ext = url.split('.').pop() || '';
      if (videoTypes.includes(ext)) {
        return 'video';
      } else if (imageTypes.includes(ext)) {
        return 'image';
      } else if (docTypes.includes(ext)) {
        return 'document';
      }
    }
    return '';
  }

  async convertImageToBase64(imageUrl: string): Promise<string> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        // Ensure the base64 string is in JPEG format
        if (!base64data.startsWith('data:image/jpeg')) {
          const img = new Image();
          img.src = base64data;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx?.drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/jpeg'));
          };
          img.onerror = reject;
        } else {
          resolve(base64data);
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  truncateText(text: string, maxLength: number): string {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.substring(0, maxLength) + '...';
  }

  // MARK: BreadCrumb Helper
  setBreadcumb(data:{label:string, url:string}[]) {
    this.routerData.next(data);
  }
}
